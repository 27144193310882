import { AnswersItem, Maybe, QuestionItem } from '__generated__/graphql';
import { meanOf } from 'utils';

export interface ComputedPersonality {
  neuroticism: number;
  extraversion: number;
  intellect_imagination: number;
  agreeableness: number;
  conscientiousness: number;
}
export const computePersonality = (answers: Maybe<AnswersItem>, questions: Maybe<QuestionItem>[]): ComputedPersonality => {
  return {
    neuroticism: meanOf('neuroticism', answers, questions),
    extraversion: meanOf('extraversion', answers, questions),
    intellect_imagination: meanOf('intellect_imagination', answers, questions),
    agreeableness: meanOf('agreeableness', answers, questions),
    conscientiousness: meanOf('conscientiousness', answers, questions),
  };
};
