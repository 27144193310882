import { useSearchParams } from 'react-router-dom';

const useCompanyParams = () => {
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('company') || '';

  const storageId = localStorage.getItem('company');
  // if a team has not been selected and no teamId in local storage, use the team from api
  if (!storageId) {
    localStorage.setItem('company', companyId);
  }

  return companyId || storageId;
};

export { useCompanyParams };
