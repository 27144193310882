import { useQuery, gql } from '@apollo/client';
import { Maybe, Question } from '__generated__/graphql';

export const GET_SURVEY_QUESTIOMS = gql`
  query GetSurveyQuestions {
    GetSurveyQuestions {
      personality {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
      }
      interests {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
      }
      values {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
      }
      customer {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
      }
    }
  }
`;

interface GetSurveyQuestionsResponse {
  GetSurveyQuestions: Maybe<Question>;
}

const useGetQuestions = () => {
  const {
    loading: questionLoading,
    data: { GetSurveyQuestions: questions = {} } = {},
    error: questionError = {},
    refetch,
  } = useQuery<GetSurveyQuestionsResponse>(GET_SURVEY_QUESTIOMS);

  return {
    questions: {
      personality: questions?.personality || [],
      interests: questions?.interests || [],
      values: questions?.values || [],
      customer: questions?.customer || [],
    },
    refetch,
    questionLoading,
    questionError,
  };
};

export { useGetQuestions };
