import { ApolloProvider } from '@apollo/client';
import { client } from './api';
import Routes from './routes';
import { UserProvider } from './context/userContext';

export const App = () => {
  return (
    <div className="bg-slate-600 h-full">
      <ApolloProvider client={client}>
        <UserProvider>
          <Routes />
        </UserProvider>
      </ApolloProvider>
    </div>
  );
};
