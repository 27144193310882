const ThankYou = () => {
  return (
    <div className="my-10 px-4 md:px-[5%]">
      <div className="flex flex-col items-center justify-center text-center text-white gap-20">
        <div>
          <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">Thank you for your time!</div>
          <p className="text-white">We will be in touch soon to share the next steps with you.</p>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
