import { ReactNode } from 'react';

interface DeckButtonContainerProps {
  onClickButton: (value: string) => void;
  children: ReactNode;
}

const DeckButtonContainer = ({ onClickButton, children }: DeckButtonContainerProps) => {
  return (
    <div className="flex items-center justify-center xl:gap-10">
      <div className=" z-50">
        <button
          type="button"
          aria-label="No"
          className="w-14	 h-14 rounded-full	bg-error-500 flex items-center justify-center"
          onClick={() => {
            onClickButton('left');
          }}
        >
          <img src="/img/thumbs_down_lg.png" className="w-6	 h-6" alt="No" />
        </button>
      </div>

      {children}
      <div className="z-50">
        <button
          type="button"
          aria-label="Yes"
          className="w-14	 h-14 rounded-full border-2	bg-success-500 flex items-center justify-center"
          onClick={() => {
            onClickButton('right');
          }}
        >
          <img src="/img/thumbs_up_lg.png" className="w-6	 h-6" alt="Yes" />
        </button>
      </div>
    </div>
  );
};

export { DeckButtonContainer };
