import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';
import { SpringValue, animated } from 'react-spring';

const AnswerSpringOverlay = ({ springs }: { springs: { opacity: SpringValue<number> }[] }) => {
  return (
    <>
      {springs.map((s, i) => {
        const icon = i === 0 ? <HandThumbDownIcon className="w-20 h-20 text-red-500" /> : <HandThumbUpIcon className="w-20 h-20  text-success-500" />;
        return (
          <animated.div
            key={i}
            className="absolute inset-0 bg-primary-800  rounded-md z-50"
            style={{
              opacity: s.opacity.to((o) => o),
            }}
          >
            <div className="absolute inset-0 flex items-center justify-center">{icon}</div>
          </animated.div>
        );
      })}
    </>
  );
};

export { AnswerSpringOverlay };
