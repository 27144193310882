import { gql, useQuery } from '@apollo/client';
import { Company } from '__generated__/graphql';

export const GET_COMPANY = gql`
  query GetCompany($query: CompanySearch) {
    GetCompany(query: $query) {
      id
      name
    }
  }
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    GetCompanies {
      id
      name
    }
  }
`;

interface GetCompaniesResponse {
  GetCompanies: Company[];
}

interface GetCompanyResponse {
  GetCompany: Company;
}

const useGetCompanies = () => {
  const { data, loading, error, refetch } = useQuery<GetCompaniesResponse>(GET_COMPANIES, {});
  return {
    companies: data?.GetCompanies,
    companyLoading: loading,
    errorCompany: error,
    refetch,
  };
};

const useGetCompany = (id: string | null) => {
  const { data, loading, error, refetch } = useQuery<GetCompanyResponse>(GET_COMPANY, {
    variables: {
      query: {
        id,
      },
    },
  });
  return {
    company: data?.GetCompany,
    userLoading: loading,
    errorUser: error,
    refetch,
  };
};

export { useGetCompanies, useGetCompany };
