import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { useCompanyParams } from 'hooks/useCompanyParams';
import Header from './Header';
import NoCompany from './NotCompany';

function Layout() {
  const company = useCompanyParams();
  return (
    <div className="fixed top-0 left-0 right-0 h-full overflow-y-scroll bg-primary-600 ">
      <Suspense fallback={null}>
        <Header />
        {company ? <Outlet /> : <NoCompany />}
      </Suspense>
    </div>
  );
}

export default Layout;
