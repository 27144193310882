import { postcodeValidator } from 'postcode-validator';

function isValidEmail(value: string) {
  const re = /\S+@\S+\.\S+/;
  if (re.test(value)) {
    return true;
  } else {
    return 'Please enter valid email';
  }
}

function isValueRequired(value: string) {
  if (value?.toString()?.trim().length > 0) {
    return true;
  } else {
    return 'This is required';
  }
}

const isPostcodeValid = (value: string) => {
  if (!value) {
    return true;
  } else if (postcodeValidator(value, 'GB')) {
    return true;
  } else {
    return 'Please enter valid UK postcode';
  }
};

export { isValueRequired, isValidEmail, isPostcodeValid };
