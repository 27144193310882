import { useState, lazy } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Title from './Title';
/* eslint-disable @typescript-eslint/no-explicit-any */

const Drawer = lazy(() => import('../Drawer'));

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Drawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} />

      <div className="shadow-header py-4 px-6">
        <div className="flex justify-between items-center">
          <div>
            <button data-cy="drawer" className="shadow-button rounded-full p-2" type="button" onClick={() => setDrawerOpen(true)} aria-label="open menu">
              <Bars3Icon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
            </button>
          </div>

          <div className="flex items-center gap-4">
            <div className="hidden md:block">
              <Title />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
