interface SwipeOverlayProps {
  active: number | boolean;
}
function SwipeOverlay({ active }: SwipeOverlayProps) {
  return (
    <>
      <div className={`w-72	h-86 absolute inset-0 bg-primary-800 ${active ? 'opacity-80' : 'opacity-0'} rounded-md`} />
      <div className={`absolute inset-0 flex items-center justify-center ${active ? 'opacity-100' : 'opacity-0'}`}>
        <img className="w-52	h-44	" src="/img/swipe_reminder.png" alt="Swipe right if you agree, swipe left if you disagree" />
      </div>
    </>
  );
}

export default SwipeOverlay;
