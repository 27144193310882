import { useNextStep } from 'hooks/useStep';
import { useUser } from '../context/userContext';

const ResultsIntro = () => {
  const { nextStep } = useNextStep();
  const { saveCompleteProcess } = useUser();

  const onComplete = () => {
    saveCompleteProcess();
    nextStep();
  };

  return (
    <div className="my-10 px-4 md:px-[5%]">
      <div className="flex flex-col items-center justify-center text-center text-white gap-20">
        <div className="ps-3">
          <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">Almost done</div>
          <p className="text-white">Thanks for taking our test!</p>
        </div>
        <img className="w-20 h-20" src="/icon/puzzle_head.png" alt="puzzle head" />
        <p className="text-white">Please click submit to send your results</p>
        <button className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md " type="button" onClick={() => onComplete()}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ResultsIntro;
