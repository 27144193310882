import { useNavigate, useLocation } from 'react-router-dom';

const steps = [
  '/',
  '/profile',
  '/questions/personality',
  '/questions/interests/instructions',
  '/questions/interests',
  '/questions/values/instructions',
  '/questions/values',
  '/questions/customer/instructions',
  '/questions/customer',
  '/results-intro',
  '/personality-results',
  '/interests-results',
  '/thank-you',
];

export const useNextStep = (): { nextStep: () => void } => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentIndex = steps.indexOf(location.pathname);
  const nextIndex = currentIndex + 1;
  const nextStep = () => {
    navigate(steps[nextIndex]);
  };
  return { nextStep };
};
