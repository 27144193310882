import { Maybe } from '__generated__/graphql';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Progress from './Progress';
import './ProgressStepper.css';

interface ProgressStepperProps {
  backOnClick: () => void;
  progressActive: number;
  forwardOnClick: () => void;
  items: ('AnswersItem' | Maybe<boolean> | undefined)[] | { image: string; caption?: string | undefined }[];
  className?: string;
  backDisabled: boolean;
  forwardDisabled: boolean;
  progressCount?: string;
}

const ProgressStepper = ({
  backOnClick,
  progressActive,
  forwardOnClick,
  items,
  forwardDisabled,
  backDisabled,
  progressCount,
  className = 'fixed',
}: ProgressStepperProps) => {
  return (
    <div className={`progress_container progress_container--${className}`}>
      <div>
        <button
          type="button"
          aria-label="Back Progress Button"
          disabled={backDisabled}
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={backOnClick}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" />
        </button>
        <Progress items={items} active={progressActive} />

        <button
          type="button"
          aria-label="Forward Progress Button"
          disabled={forwardDisabled}
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={forwardOnClick}
        >
          <ArrowRightIcon className="mr-2 w-5 h-5" />
        </button>
      </div>
      {progressCount && <div className="progress_container__count yuno-gray">{progressCount}</div>}
    </div>
  );
};

export { ProgressStepper };
