import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from '../FormLabel';
import ReactSelect from '../Select';
import { isValueRequired } from './validators';

export type SelectOption = {
  value: string;
  label: string;
  id?: string;
};

interface SelectFieldProps {
  label: string;
  name: string;
  asRow?: boolean;
  options: SelectOption[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

interface SelectFieldWithOutLabelProps {
  name: string;
  options: SelectOption[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

const SelectFieldWithoutLabel = ({ name, options, disabled = false, loading = false, searchable, testId, isRequired }: SelectFieldWithOutLabelProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: isRequired ? isValueRequired : undefined }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const selectedValue = options.find((option) => option.value === value);
        return (
          <>
            <ReactSelect
              formRef={register(name).ref}
              testId={testId}
              isLoading={loading}
              disabled={disabled}
              options={options}
              onChange={(e) => onChange(e.value)}
              selectedValue={selectedValue}
              searchable={searchable}
            />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </>
        );
      }}
    />
  );
};
const SelectField = (props: SelectFieldProps) => {
  return (
    <FormLabel asRow={props.asRow} label={props.label}>
      <div className="w-full lg:w-1/2">
        <SelectFieldWithoutLabel {...props} />
      </div>
    </FormLabel>
  );
};

export { SelectField, SelectFieldWithoutLabel };
