import { Maybe } from '__generated__/graphql';
import classNames from 'classnames';

interface ProgressProps {
  items: ('AnswersItem' | Maybe<boolean> | undefined)[] | { image: string; caption?: string | undefined }[];
  active: number;
}

const Progress = ({ items, active }: ProgressProps) => {
  return (
    <ul className="progress_dots">
      {items.map((item, i) => {
        const classes = classNames(
          'answer',
          { 'answer--yes': i < active && item === true },
          { 'answer--no': i < active && item === false },
          { 'answer--active': active === i },
        );

        return (
          <li key={`answer-${i}`} className={classes}>
            <span />
          </li>
        );
      })}
    </ul>
  );
};

export default Progress;
