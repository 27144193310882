import { useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/userContext';

interface LeftOffDialogProps {
  setLeftOffUrl: Dispatch<SetStateAction<string | undefined>>;
  leftOffUrl: string | undefined;
}
const LeftOffDialog = ({ setLeftOffUrl, leftOffUrl }: LeftOffDialogProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate();
  const { saveUserAnswers } = useUser();

  const ignoreLeftOff = () => {
    setLeftOffUrl(undefined);
    navigate('/profile');
    saveUserAnswers({}, {});
  };

  useEffect(() => {
    // Draw stuff on Canvas
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const center = Math.floor(window.innerWidth / 2);

    // Define the points as {x, y}
    const curves = [
      {
        start: { x: 45, y: 60 },
        cp1: { x: 120, y: 121 },
        cp2: { x: center + 81, y: 9 },
        end: { x: center + 27, y: 125 },
      },
      {
        start: { x: center - 30, y: 270 },
        cp1: { x: center - 45, y: Math.floor((window.innerHeight * 3) / 5) },
        cp2: {
          x: window.innerWidth,
          y: Math.floor((window.innerHeight * 3) / 5) + 30,
        },
        end: { x: center + 40, y: window.innerHeight - 122 },
      },
    ];

    curves.forEach(({ start, cp1, cp2, end }) => {
      ctx?.beginPath();
      if (ctx?.strokeStyle) ctx.strokeStyle = 'white';
      ctx?.setLineDash([5, 4]);
      ctx?.moveTo(start.x, start.y);
      ctx?.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y);
      ctx?.stroke();
    });
  });

  return (
    <div className="w-full h-full 	bg-primary-600 pt-20">
      <canvas className="fixed top-0 left-0 z-10 	" ref={canvasRef} width={window.innerWidth} height={window.innerHeight - 102} />
      <div className="fixed text-center left-0 right-0 top-32 mt-4 z-50">
        <h2 className="	">Welcome back!</h2>

        <button
          type="button"
          onClick={() => {
            if (leftOffUrl) navigate(leftOffUrl);
          }}
          className="bg-white text-black p-2	h-8	uppercase rounded-md font-semibold text-md leading-md"
        >
          Continue where you left off
        </button>
        <div>or </div>
        <div>Start from the beginning</div>
        {/* <div>
          <div className=" h-3	w-3 fixed z-50 left-9 top-14	"></div>
          <div className="left-off__arrow-end left-off__arrow-end--bottom"></div>
        </div> */}
        <button
          type="button"
          onClick={() => ignoreLeftOff()}
          className="bg-white text-black p-2 bottom-0	h-8	uppercase rounded-md font-semibold text-md leading-md"
        >
          Start fresh
        </button>
      </div>
    </div>
  );
};

export { LeftOffDialog };
