import { useEffect, useState } from 'react';
import { ProgressStepper } from './ProgressStepper';

const Carousel = ({ items }: { items: { image: string; caption?: string }[] }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const carouselInfiniteScroll = () => {
    if (currentIndex === items.length - 1) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex + 1);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      carouselInfiniteScroll();
    }, 4000);
    return () => clearInterval(interval);
  });
  return (
    <>
      <div className="flex flex-nowrap overflow-hidden mb-2">
        {items.map((item, index) => {
          return (
            <div
              className="flex items-center flex-col w-full h-full min-w-full  min-h-80	"
              style={{ transform: `translate(-${currentIndex * 100}%)` }}
              key={index}
            >
              <img src={item.image} className="xl:h-40 	h-28	 m-4" alt="..." />

              <p className="text-white">{item.caption}</p>
            </div>
          );
        })}
      </div>
      <ProgressStepper
        backDisabled={currentIndex < 0}
        backOnClick={() => setCurrentIndex(currentIndex - 1)}
        progressActive={currentIndex}
        forwardDisabled={currentIndex === items.length - 1}
        forwardOnClick={() => setCurrentIndex(currentIndex + 1)}
        items={items}
        className="wide"
      />
    </>
  );
};

export { Carousel };
