import { gql, useQuery } from '@apollo/client';
import { JobRole } from '__generated__/graphql';

export const GET_JOB_ROLES = gql`
  query GetJobRoles {
    GetJobRoles {
      id
      name
    }
  }
`;

interface GetJobRolesResponse {
  GetJobRoles: JobRole[];
}

const useGetJobRoles = () => {
  const { data, loading, error, refetch } = useQuery<GetJobRolesResponse>(GET_JOB_ROLES, {});
  return {
    jobRoles: data?.GetJobRoles,
    jobRoleLoading: loading,
    errorJobRole: error,
    refetch,
  };
};

export { useGetJobRoles };
