import { useGetCompanies } from 'api/hooks/useCompany';
import { SelectField } from './SelectField';

interface CompanySelectFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
  testId?: string;
  searchable?: boolean;
}

const CompanySelectField = ({ label, name, disabled = false, searchable, testId }: CompanySelectFieldProps) => {
  const { companies, companyLoading } = useGetCompanies();
  const companyOptions = companies?.map((s, index) => {
    return { label: s.name, value: s.id, id: index.toString() };
  });

  return (
    <SelectField
      isRequired={true}
      loading={companyLoading}
      searchable={searchable}
      testId={testId}
      label={label}
      name={name}
      options={companyOptions || []}
      disabled={disabled}
    />
  );
};

export { CompanySelectField };
