import { AnswersItem, Maybe, QuestionItem } from '__generated__/graphql';
import { meanOf } from 'utils';

export interface ComputedVocationalType {
  realistic: number;
  investigative: number;
  artistic: number;
  social: number;
  enterprising: number;
  conventional: number;
}

export const computeVocationalTypes = (answers: Maybe<AnswersItem>, questions: Maybe<QuestionItem>[]) => {
  return {
    realistic: meanOf('realistic', answers, questions),
    investigative: meanOf('investigative', answers, questions),
    artistic: meanOf('artistic', answers, questions),
    social: meanOf('social', answers, questions),
    enterprising: meanOf('enterprising', answers, questions),
    conventional: meanOf('conventional', answers, questions),
  };
};
