import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { QuestionItem, Maybe } from '__generated__/graphql';
import { SpringValue, animated, to } from 'react-spring';
import Loading from 'components/Loading';
import SwipeOverlay from './Overlays/SwipeOverlay';
import { AnswerSpringOverlay } from './Overlays/AnswerSpringOverlay';
import { DeckButtonContainer } from '../DeckButtonContainer';

interface CardProps {
  idx: SpringValue<number>;
  rot: SpringValue<number>;
  scale: SpringValue<number>;
  bind: (...args: SpringValue<number>[]) => ReactDOMAttributes;
  question: Maybe<QuestionItem>;
  instructionsActive: number | boolean;
  handleCardTouch: () => void;
  springs: { opacity: SpringValue<number> }[];
  onClickButton: (value: string) => void;
}

const Card = ({ idx, rot, scale, bind, question, instructionsActive, handleCardTouch, springs, onClickButton }: CardProps) => {
  const trans = (r: number, s: number) => `perspective(1500px) scale(${s}) rotate(${r}deg)`;

  const { id, title, pic } = question || {};

  return (
    <animated.div className="col-start-1 row-start-1 " key={id}>
      <animated.div
        {...bind(idx)}
        style={{
          transform: to([rot, scale], trans),
        }}
      >
        {question ? (
          <div
            className="bg-primary-600 text-white h-full max-w-72 text-center break-words "
            onTouchStart={() => handleCardTouch()}
            onMouseDown={() => handleCardTouch()}
          >
            <div className="relative">
              <DeckButtonContainer onClickButton={onClickButton}>
                <img key={pic} src={`/${pic}`} draggable="false" onDragStart={(e) => e.preventDefault()} alt={`${id}: ${title}`} />
                <SwipeOverlay active={instructionsActive} />
                <AnswerSpringOverlay springs={springs} />
              </DeckButtonContainer>
            </div>
            <div className="h-40 text-lg	">
              <h1>{title}</h1>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </animated.div>
    </animated.div>
  );
};

export default Card;
