import { createBrowserHistory } from 'history'; // browser history
import { useNextStep } from 'hooks/useStep';
import PersonalityChart from './PersonalityChart';
import { computePersonality } from './utils';
import { useUser } from '../../context/userContext';
import Loading from '../../components/Loading';

const PersonalityProfile = () => {
  const { answers, questions, userLoading, questionLoading } = useUser();
  const { nextStep } = useNextStep();
  if (userLoading || questionLoading) return <Loading />;

  const personality = computePersonality(answers, questions.personality || []);
  const history = createBrowserHistory();

  const { hash } = history.location;
  if (!personality) {
    return null;
  }

  return (
    <div id="result-views" className="my-10 px-4 md:px-[5%]">
      <div id="personality-results" className="flex flex-col items-center justify-center text-center text-white gap-20 ">
        <PersonalityChart personality={personality} hash={hash} />
        <button type="button" className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md " onClick={() => nextStep()}>
          Show work interests
        </button>
      </div>
    </div>
  );
};

export default PersonalityProfile;
