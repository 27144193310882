import { useEffect, useState } from 'react';
import { useNextStep } from 'hooks/useStep';
import { LeftOffDialog } from './LeftOffDialog';
import { Carousel } from '../../components/Carousel';
import { useUser } from '../../context/userContext';
import { checkQuestionsAnswered, isAnswerEmpty } from '../../utils';
import Loading from '../../components/Loading';
import { AnswersItem, Maybe, Question } from '../../__generated__/graphql';

/** this method checks whether the the state would suggest that the user left off in a previous session. */
const whereLeftOff = (allQuestionsAnswered: boolean | undefined, questions: Maybe<Question>, answers: Maybe<AnswersItem>) => {
  if (!isAnswerEmpty(answers)) {
    if (allQuestionsAnswered) {
      // All questions answered, go to results
      return './results-intro';
    }
    if (!checkQuestionsAnswered(questions?.customer, answers)) {
      return '/questions/customer';
    }
    if (!checkQuestionsAnswered(questions?.values, answers)) {
      return '/questions/values';
    }
    if (!checkQuestionsAnswered(questions?.interests, answers)) {
      return '/questions/interests';
    }
    if (!checkQuestionsAnswered(questions?.personality, answers)) {
      return '/questions/personality';
    }
  }
};

const AppInstructions = () => {
  const { nextStep } = useNextStep();
  const carousel: { image: string; caption?: string }[] = [
    {
      image: '/companyLogo.png',

      caption:
        // eslint-disable-next-line max-len
        'Welcome to TeamFit, a simple values and personality survey that will help us identify the best position for you. This will take only 5 minutes and can be completed on your phone or computer.',
    },
    {
      image: '../img/swipe.png',
      caption: 'You will be asked to swipe left or right to answer simple questions. Remember, there are no wrong or right answers!',
    },
    {
      image: '/high-five.png',

      caption:
        'We will assess the results and match you with the best job roles and ensure you will fit perfectly into the right team. We will send you the results so you can share them with employers',
    },
  ];
  const { allQuestionsAnswered, questions, answers, userLoading } = useUser();
  const [leftOffUrl, setLeftOffUrl] = useState<string>();

  useEffect(() => {
    const defaultLeftOffUrl = whereLeftOff(allQuestionsAnswered, questions, answers);

    if (defaultLeftOffUrl) {
      setLeftOffUrl(defaultLeftOffUrl);
    }
  }, [allQuestionsAnswered, answers, questions]);
  if (userLoading) return <Loading />;

  return (
    // add fade in animation
    <div className="my-10 px-4 md:px-[5%]">
      <div className="flex flex-col items-center justify-center text-center text-white gap-10">
        <div className="ps-3">
          <div className="text-white text-xl text-center sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">Teamfit and Me</div>
        </div>
        {leftOffUrl && <LeftOffDialog key={1} setLeftOffUrl={setLeftOffUrl} leftOffUrl={leftOffUrl} />}
        <div className="z-10 mb-2">
          <Carousel items={carousel} />
        </div>
        <button type="button" className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md " onClick={() => nextStep()}>
          Get started
        </button>
      </div>
    </div>
  );
};

export { AppInstructions };
