import { createBrowserHistory } from 'history'; // browser history
import { useUser } from 'context/userContext';
import { useNextStep } from 'hooks/useStep';
import Loading from 'components/Loading';
import { computeVocationalTypes } from './utils';
import InterestsChart from './InterestsChart';

const InterestsProfile = () => {
  const { nextStep } = useNextStep();
  const history = createBrowserHistory();
  const { hash } = history.location;
  const { answers, questions, userLoading, questionLoading } = useUser();
  if (userLoading || questionLoading) return <Loading />;

  const vocationalTypes = computeVocationalTypes(answers, questions.interests || []);

  if (!vocationalTypes) {
    return null;
  }

  return (
    <div id="result-views" className="my-10 px-4 md:px-[5%]">
      <div id="work-interests-results" className="flex flex-col items-center justify-center text-center text-white gap-20">
        <InterestsChart vocationalTypes={vocationalTypes} hash={hash} />
        <button className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md " type="button" onClick={() => nextStep()}>
          Last step
        </button>
      </div>
    </div>
  );
};

export default InterestsProfile;
