import Questions from 'pages/Questions';
import { AppInstructions } from 'pages/AppInstruction';
import Profile from 'pages/Profile';
import SectionBreak from 'pages/SectionBreak';
import { RouteObject } from 'react-router-dom';
import ResultsIntro from 'pages/ResultsIntro';
import ThankYou from 'pages/ThankYou';
import PersonalityProfile from 'pages/PersonailityProfile';
import InterestsProfile from 'pages/InterestsProfile';

export const publicRoutes: RouteObject[] = [
  { path: '/', element: <AppInstructions /> },
  { path: '/profile', element: <Profile /> },
  {
    path: '/questions/personality',
    element: <Questions id="personality" title="Your Personality" icon="/icon/personality_head.png" />,
  },
  {
    path: '/questions/interests/instructions',
    element: (
      <SectionBreak
        title="Your values"
        body={['What is important to you when it comes to work?', 'Tell us what really matters to you so we can find teams that are a good fit ...']}
        icon="/icon/toolbox.png"
      />
    ),
  },
  {
    path: '/questions/interests',
    element: <Questions id="interests" title="What type of jobs might you enjoy doing?" icon="/icon/toolbox.png" />,
  },
  {
    path: '/questions/values/instructions',
    element: (
      <SectionBreak
        title="Your values"
        body={['What is important to you when it comes to work?', 'Tell us what really matters to you so we can find teams that are a good fit ...']}
        icon="/icon/head-heart.png"
      />
    ),
  },
  {
    path: '/questions/values',
    element: <Questions id="values" title="Your values" icon="/icon/head-heart.png" />,
  },
  {
    path: '/questions/customer/instructions',
    element: (
      <SectionBreak title="Last Question Section!" body={['Just a few more questions to pin down what makes a good employee fit']} icon="/icon/stopwatch.png" />
    ),
  },
  {
    path: '/questions/customer',
    element: <Questions id="customer" title="Last Question Section!" icon="/icon/stopwatch.png" />,
  },
  {
    path: '/results-intro',
    element: <ResultsIntro />,
  },
  {
    path: '/personality-results',
    element: <PersonalityProfile />,
  },
  {
    path: '/interests-results',
    element: <InterestsProfile />,
  },
  {
    path: '/thank-you',
    element: <ThankYou />,
  },
];
