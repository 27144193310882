import classNames from 'classnames';

interface ChartButtonProps {
  setActiveType: (id: string) => void;
  isActive: boolean;
  className: string;
  title: string;
  id: string;
}
const ChartButton = ({ setActiveType, isActive, className, title, id }: ChartButtonProps) => {
  return (
    <button
      // ref={`#${id}`}
      title={title}
      aria-label="Chart button"
      type="button"
      className={classNames('chart-button', className, {
        active: isActive,
      })}
      // disableRipple={true}
      onClick={() => {
        setActiveType(id);
      }}
    >
      <span>
        <span />
      </span>
    </button>
  );
};

interface ChartButtonContainerProps {
  buttons: { title: string; className: string; id: string }[];
  setActiveType: (id: string) => void;
  activeType: string;
}
const ChartButtonContainer = ({ buttons, setActiveType, activeType }: ChartButtonContainerProps) => {
  return (
    <div className="flex flex-row items-center justify-center p-2">
      {buttons.map(({ title, className, id }) => {
        return <ChartButton title={title} id={id} key={id} className={className} isActive={activeType === id} setActiveType={setActiveType} />;
      })}
    </div>
  );
};
export default ChartButtonContainer;
