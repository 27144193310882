/* eslint-disable @typescript-eslint/no-explicit-any */

const Title = () => {
  const { host } = window.location;
  const pieces = host.split('.');
  let subdomain;

  const environment = () => {
    if (pieces.length >= 1) {
      // eslint-disable-next-line prefer-destructuring
      subdomain = pieces[0];
      subdomain = subdomain.split('-');
      return subdomain;
    }
    return null;
  };

  const displayEnvBanner = () => {
    const env = environment();
    if (env !== null) {
      if (env[0] === 'test') {
        return ': Test';
      }
      if (env[0] === 'dev') {
        return ': Dev';
      }
    }
    return '';
  };

  return (
    <div data-cy="welcome-user" className="text-lg leading-lg text-primary-700 font-medium">
      {' Welcome to teamfit-survey'}
      {displayEnvBanner()}
    </div>
  );
};

export default Title;
